import React from 'react';
import {
  DappUI,
  useGetLoginInfo,
  logout,
  useGetAccountInfo,
  getAccount
} from '@elrondnetwork/dapp-core';
import { useTranslation } from 'react-i18next';

function AdminComponent() {
  const { address, account } = useGetAccountInfo();
  const { isLoggedIn } = useGetLoginInfo();
  const { t, i18n } = useTranslation('common');
  const [nfts, setNfts] = React.useState([]);
  const environment = process.env.REACT_APP_ENVIRONMENT;
  var uri = '';
  if (environment === 'testnet') {
    uri = 'https://testnet-api.elrond.com/';
  } else if (environment === 'mainnet') {
    uri = 'https://api.elrond.com/';
  } else if (environment === 'devnet') {
    uri = 'https://devnet-api.elrond.com/';
  }

  React.useEffect(async () => {
    console.log(uri);
    const lang = localStorage.getItem('i18nextLng');
    if (lang) {
      i18n.changeLanguage(lang);
    }
    console.log(address);
    await fetch(uri + 'accounts/' + address + '/nfts').then((res) => {
      res.json().then((data) => {
        console.log(data);
        setNfts(data);
      });
    });
  }, []);
  const NFTSList = () => {
    const list = [];
    nfts.map((nft, key) => {
      list.push(
        <div className='col-md-3 col-sm-6' key={key}>
          <div className='shop-item mb-60'>
            <div className='shop-thumb'>
              <img
                src='assets/img/shop/shop_wrap_img02.png'
                alt='img'
                className='shape'
              />
              <a href='/shop-details '>
                <img
                  src={nft.media[0].url}
                  alt='img'
                  className='shop-img nft-img'
                />
              </a>
              <div className='shop-date'>
                <span>{nft.collection}</span>
              </div>
              {/* <div className='shop-share'>
                <a href='/shop-details '>
                  <i className='fas fa-share-alt' />
                </a>
              </div> */}
            </div>
            <div className='shop-content'>
              <div className='shop-content-top'>
                <div className='shop-title'>
                  <h5 className='title'>
                    <a href='/shop-details '>{nft.name}</a>
                  </h5>
                  {/* <p>E-SPORTS</p> */}
                </div>
                <div className='price'>
                  <span>18 ERMS</span>
                </div>
              </div>
              <div className='shop-content-bottom'>
                <div className='shop-btn'>
                  <a href='/shop-details ' className='btn'>
                    <i className='fas fa-plus' />
                    get nft
                  </a>
                </div>
                <div className='shop-wishlist'>
                  <a href='/shop-details '>
                    <i className='far fa-heart' />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
    return list;
  };
  return (
    <section className='inner-shop-area black-bg'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-xl-12'>
            <div className='shop-top-meta'>
              <p className='shop-show-result'>Total items {nfts.length}</p>
              <div className='shop-top-right'>
                <form action='#'>
                  <label htmlFor='shortBy'>sort By</label>
                  <select
                    id='shortBy'
                    name='select'
                    className='form-select'
                    aria-label='Default select example'
                  >
                    <option value>Sort by Latest</option>
                    <option>Free Shipping</option>
                    <option>Best Match</option>
                    <option>Newest Item</option>
                    <option>Size A - Z</option>
                  </select>
                </form>
              </div>
            </div>
            <div className='row justify-content-center'>
              <NFTSList />
              {/* <div className='pagination-wrap mt-10'>
                <nav>
                  <ul>
                    <li className='active'>
                      <a href='/#'>01</a>
                    </li>
                    <li>
                      <a href='/#'>02</a>
                    </li>
                    <li>
                      <a href='/#'>03</a>
                    </li>
                    <li>
                      <a href='/#'>04</a>
                    </li>
                    <li>
                      <a href='/#'>Next</a>
                    </li>
                  </ul>
                </nav>
              </div> */}
            </div>
          </div>
          {/* <div className='col-xl-4 col-lg-6'>
            <aside className='shop-sidebar'>
              <div className='widget'>
                <h4 className='widget-title'>Searching</h4>
                <form action='#' className='sidebar-search'>
                  <input type='text' placeholder='Search...' />
                  <button>
                    <i className='fas fa-search' />
                  </button>
                </form>
              </div>
              <div className='widget'>
                <h4 className='widget-title'>price filter</h4>
                <div className='price_filter'>
                  <div id='slider-range' />
                  <div className='price_slider_amount'>
                    <span>Price :</span>
                    <input
                      type='text'
                      id='amount'
                      name='price'
                      placeholder='Add Your Price'
                    />
                  </div>
                  <input type='submit' className='btn' defaultValue='Filter' />
                </div>
              </div>
              <div className='widget'>
                <h4 className='widget-title'>CATEGORIES</h4>
                <ul className='shop-sidebar-cat'>
                  <li>
                    <a href='/#'>
                      E-SPORTS <span>26</span>
                    </a>
                  </li>
                  <li>
                    <a href='/#'>
                      xbox controller <span>24</span>
                    </a>
                  </li>
                  <li>
                    <a href='/#'>
                      Head phone <span>06</span>
                    </a>
                  </li>
                  <li>
                    <a href='/#'>
                      Gaming Mask <span>12</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className='widget'>
                <h4 className='widget-title'>tagS</h4>
                <ul className='shop-sidebar-tags'>
                  <li>
                    <a href='/#'>game</a>
                  </li>
                  <li>
                    <a href='/#'>offline</a>
                  </li>
                  <li>
                    <a href='/#'>online gaming</a>
                  </li>
                  <li>
                    <a href='/#'>shooting</a>
                  </li>
                  <li>
                    <a href='/#'>online games</a>
                  </li>
                  <li>
                    <a href='/#'>X-box</a>
                  </li>
                  <li>
                    <a href='/#'>zombie games</a>
                  </li>
                </ul>
              </div>
            </aside>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default AdminComponent;
