import React from 'react';

function OurShop() {
  const nfts = [
    {
      src: 'assets/img/nft/AmoDoBazooka.PNG',
      name: 'AmoDoBazooka',
      price: '0.5'
    },
    {
      src: 'assets/img/nft/CargusRadioactivus.PNG',
      name: 'CargusRadioactivus',
      price: '0.5'
    },
    {
      src: 'assets/img/nft/Commando.PNG',
      name: 'Commando',
      price: '0.5'
    },
    {
      src: 'assets/img/nft/Interceptor.PNG',
      name: 'Interceptor',
      price: '0.5'
    },
    {
      src: 'assets/img/nft/Lightning.png',
      name: 'Lightning',
      price: '0.5'
    },
    {
      src: 'assets/img/nft/PistoletoBlindato.PNG',
      name: 'PistoletoBlindato',
      price: '0.5'
    },
    {
      src: 'assets/img/nft/SilverHawk.PNG',
      name: 'SilverHawk',
      price: '0.5'
    },
    {
      src: 'assets/img/nft/SpitFire.PNG',
      name: 'SpitFire',
      price: '0.5'
    },
    {
      src: 'assets/img/nft/Thunderbolt.PNG',
      name: 'Thunderbolt',
      price: '0.5'
    }
  ];
  return (
    <section className='inner-shop-area black-bg'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-xl-12'>
            <div className='shop-top-meta'>
              <p className='shop-show-result'>Total Items {nfts.length}</p>
              <div className='shop-top-right'>
                <form action='#'>
                  <label htmlFor='shortBy'>sort By</label>
                  <select
                    id='shortBy'
                    name='select'
                    className='form-select'
                    aria-label='Default select example'
                  >
                    <option value>Sort by Latest</option>
                    <option>Free Shipping</option>
                    <option>Best Match</option>
                    <option>Newest Item</option>
                    <option>Size A - Z</option>
                  </select>
                </form>
              </div>
            </div>
            <div className='row justify-content-start'>
              {nfts.map((nft, key) => {
                return (
                  <div className='col-md-3 col-sm-6' key={key}>
                    <div className='shop-item mb-60'>
                      <div className='shop-thumb'>
                        <img
                          src='assets/img/shop/shop_wrap_img02.png'
                          alt='img'
                          className='shape'
                        />
                        <a href='https://xoxno.com/buy/ERMS/Assets'>
                          <img
                            src={nft.src}
                            alt='img'
                            className='shop-img nft-img'
                          />
                        </a>
                        <div className='shop-date'>
                          <span>ERMS</span>
                        </div>
                        {/* <div className='shop-share'>
                          <a href='https://xoxno.com/buy/ERMS/Assets'>
                            <i className='fas fa-share-alt' />
                          </a>
                        </div> */}
                      </div>
                      <div className='shop-content'>
                        <div className='shop-content-top'>
                          <div className='shop-title'>
                            <h5 className='title'>
                              <a href='https://xoxno.com/buy/ERMS/Assets'>
                                {nft.name}
                              </a>
                            </h5>
                            {/* <p>E-SPORTS</p> */}
                          </div>
                          <div className='price'>
                            <span>{nft.price} EGLD</span>
                          </div>
                        </div>
                        <div className='shop-content-bottom'>
                          <div className='shop-btn'>
                            <a
                              href='https://xoxno.com/buy/ERMS/Assets '
                              className='btn'
                            >
                              <i className='fas fa-plus' />
                              get nft
                            </a>
                          </div>
                          <div className='shop-wishlist'>
                            <a href='https://xoxno.com/buy/ERMS/Assets '>
                              <i className='far fa-heart' />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              {/* <div className='pagination-wrap mt-10'>
                <nav>
                  <ul>
                    <li className='active'>
                      <a href='/#'>01</a>
                    </li>
                    <li>
                      <a href='/#'>02</a>
                    </li>
                    <li>
                      <a href='/#'>03</a>
                    </li>
                    <li>
                      <a href='/#'>04</a>
                    </li>
                    <li>
                      <a href='/#'>Next</a>
                    </li>
                  </ul>
                </nav>
              </div> */}
            </div>
          </div>
          {/* <div className='col-xl-4 col-lg-6'>
            <aside className='shop-sidebar'>
              <div className='widget'>
                <h4 className='widget-title'>Searching</h4>
                <form action='#' className='sidebar-search'>
                  <input type='text' placeholder='Search...' />
                  <button>
                    <i className='fas fa-search' />
                  </button>
                </form>
              </div>
              <div className='widget'>
                <h4 className='widget-title'>price filter</h4>
                <div className='price_filter'>
                  <div id='slider-range' />
                  <div className='price_slider_amount'>
                    <span>Price :</span>
                    <input
                      type='text'
                      id='amount'
                      name='price'
                      placeholder='Add Your Price'
                    />
                  </div>
                  <input type='submit' className='btn' defaultValue='Filter' />
                </div>
              </div>
              <div className='widget'>
                <h4 className='widget-title'>CATEGORIES</h4>
                <ul className='shop-sidebar-cat'>
                  <li>
                    <a href='/#'>
                      E-SPORTS <span>26</span>
                    </a>
                  </li>
                  <li>
                    <a href='/#'>
                      xbox controller <span>24</span>
                    </a>
                  </li>
                  <li>
                    <a href='/#'>
                      Head phone <span>06</span>
                    </a>
                  </li>
                  <li>
                    <a href='/#'>
                      Gaming Mask <span>12</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className='widget'>
                <h4 className='widget-title'>tagS</h4>
                <ul className='shop-sidebar-tags'>
                  <li>
                    <a href='/#'>game</a>
                  </li>
                  <li>
                    <a href='/#'>offline</a>
                  </li>
                  <li>
                    <a href='/#'>online gaming</a>
                  </li>
                  <li>
                    <a href='/#'>shooting</a>
                  </li>
                  <li>
                    <a href='/#'>online games</a>
                  </li>
                  <li>
                    <a href='/#'>X-box</a>
                  </li>
                  <li>
                    <a href='/#'>zombie games</a>
                  </li>
                </ul>
              </div>
            </aside>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default OurShop;
