import React, { useEffect } from 'react';
import {
  DappUI,
  useGetLoginInfo,
  logout,
  useGetAccountInfo,
  getAccount
} from '@elrondnetwork/dapp-core';
import CloseIcon from '@mui/icons-material/Close';
import Logout from '@mui/icons-material/Logout';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import $ from 'jquery';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { routeNames } from 'routes';
import 'flag-icons';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { doc, setDoc, getFirestore, getDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { app } from '../../firebase.config';
import { useNavigate } from 'react-router-dom';

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText('#ff8503'),
  fontWeight: 'bold',
  backgroundColor: '#ff8503',
  '&:hover': {
    backgroundColor: '#e19b51'
  }
}));

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#ff8503'
  },
  '& label.MuiInputBase-root': {
    color: '#ff8503'
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#ff8503'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#ff8503'
    },
    '&:hover fieldset': {
      borderColor: '#ff8503',
      color: 'white'
    },
    '&.Mui-focused fieldset': {
      borderColor: '#ff8503'
    }
  }
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    backgroundColor: '#000000',
    borderRadius: '1px',
    border: '1px solid #fff'
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, backgroundColor: '#000000' }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

// BootstrapDialogTitle.propTypes = {
//   children: PropTypes.node,
//   onClose: PropTypes.func.isRequired,
// };

function HeaderOne() {
  const environment = process.env.REACT_APP_ENVIRONMENT;
  const navigate = useNavigate();
  const [openLoginDialog, setOpenLoginDialog] = React.useState(false);
  const [openEmailDialog, setOpenEmailDialog] = React.useState(false);
  const [emailCapture, setEmailCapture] = React.useState('');
  const [userInfo, setUserInfo] = React.useState(null);
  const { address, account } = useGetAccountInfo();
  const db = getFirestore(app);
  const auth = getAuth(app);
  const { t, i18n } = useTranslation('common');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const darkTheme = createTheme({
    palette: {
      mode: 'dark'
    }
  });

  const {
    ExtensionLoginButton,
    WebWalletLoginButton,
    LedgerLoginButton,
    WalletConnectLoginButton
  } = DappUI;
  const { isLoggedIn } = useGetLoginInfo();

  const switchLanguage = (lang) => {
    localStorage.setItem('i18nextLng', lang);
    i18n.changeLanguage(lang);
  };

  const userAction = async (user, address) => {
    await setDoc(db, 'users', 'adresamea', {
      address: address,
      lastActionTimestamp: new Date(),
      firebaseAuthId: user.uid
    });
  };

  React.useEffect(() => {
    // console.log(userInfo);
  }, [userInfo]);

  React.useEffect(async () => {
    if (address) {
      const user = auth.currentUser;
      const docRef = await getDoc(doc(db, 'users', address));
      var uriEnv = '';
      var data = {};
      const docData = docRef.data();
      if (docData) {
        const email = docData.email;
        if (email) {
          data.email = email;
        }
      }
      data.address = address;
      data.environment = environment;
      data.lastActionTimestamp = new Date();
      if (user) {
        data.firebaseAuthId = user.uid;
      }
      // console.log(environment);
      if (environment === 'testnet') {
        uriEnv = 'https://testnet-api.elrond.com/accounts/' + address;
      } else if (environment === 'mainnet') {
        uriEnv = 'https://api.elrond.com/accounts/' + address;
      }
      if (address) {
        const addressInfo = await fetch(uriEnv).then(async (res) => {
          return res.json().then((res) => {
            res.environment = environment;
            setUserInfo(res);
            return res;
          });
        });
        data.addressInfo = addressInfo;
        await setDoc(doc(db, 'users', address), data, { merge: true });
      }
    }
  }, []);

  //select user from database where address = address
  React.useEffect(async () => {
    if (address) {
      const docRef = await getDoc(doc(db, 'users', address));
      if (docRef) {
        const userData = docRef.data();
        if (userData) {
          const settedEmail = userData.email;
          if (!settedEmail) {
            console.log('no email');
            handleOpenEmailDialog();
          }
        }
      }
    }
  }, [userInfo]);

  const addEmail = async () => {
    const docRef = doc(db, 'users', address);
    const emailSet = await setDoc(
      docRef,
      { email: emailCapture },
      { merge: true }
    );
    const getUser = await getDoc(docRef);
    const settedEmail = getUser.data().email;
    if (settedEmail) {
      handleCloseEmailDialog();
      // sessionStorage.setItem('userEmail', settedEmail);
      navigate('/');
    }
  };

  React.useEffect(async () => {
    const lang = localStorage.getItem('i18nextLng');
    if (lang) {
      i18n.changeLanguage(lang);
    }
    // console.log(address);
    // await fetch(
    //   'https://api.elrond.com/accounts/' +
    //     'erd10jm9p6r4wr6zzxlkqvyusgfa7pj2468nr62akedzxwg8wa4xq2lspt0keg'
    // ).then((res) => {
    //   res.json().then((data) => {
    //     console.log(data);
    //   });
    // });
  }, []);

  // React.useEffect(() => {
  //   if (isLoggedIn) {
  //     window.location.href = routeNames.home;
  //   }
  // }, [isLoggedIn]);

  const cut = (str) => {
    if (userInfo) {
      const userTag = userInfo.username;
      if (userTag) {
        return userInfo.username;
      }
    }
    return str.substr(0, 6) + '...' + str.substr(-5, 5);
  };
  //handle login dialog
  const handleClickOpenLoginDialog = () => {
    if (isLoggedIn) {
      logout('/');
    } else {
      setOpenLoginDialog(true);
    }
  };
  const handleCloseLoginDialog = () => {
    setOpenLoginDialog(false);
  };

  //handle email capture dialog
  const handleOpenEmailDialog = () => {
    setOpenEmailDialog(true);
  };
  const handleCloseEmailDialog = () => {
    setOpenEmailDialog(false);
  };

  useEffect(() => {
    //SubMenu Dropdown Toggle
    if ($('.menu-area li.menu-item-has-children ul').length) {
      $('.menu-area .navigation li.menu-item-has-children').append(
        '<div className="dropdown-btn"><span className="fas fa-angle-down"></span></div>'
      );
    }

    //Mobile Nav Hide Show
    if ($('.mobile-menu').length) {
      // var mobileMenuContent = $('.menu-area .push-menu').html();
      // $('.mobile-menu .menu-box .menu-outer').append(mobileMenuContent);

      //Dropdown Button
      $('.mobile-menu li.menu-item-has-children .dropdown-btn').on(
        'click',
        function () {
          $(this).toggleClass('open');
          $(this).prev('ul').slideToggle(500);
        }
      );

      $('.menu-backdrop, .mobile-menu .close-btn').click(() => {
        $('body').removeClass('mobile-menu-visible');
      });

      //Menu Toggle Btn
      $('.mobile-nav-toggler').on('click', function () {
        $('body').addClass('mobile-menu-visible');
      });

      //Hide menu on click
      $('.menu-area .navigation li a').on('click', function () {
        $('body').removeClass('mobile-menu-visible');
      });
    }
  }, []);

  useEffect(() => {
    $('.menu-tigger').on('click', function () {
      $('.extra-info,.offcanvas-overly').addClass('active');
      return false;
    });
    $('.menu-close,.offcanvas-overly').on('click', function () {
      $('.extra-info,.offcanvas-overly').removeClass('active');
    });
    /*=============================================
      =     Menu sticky & Scroll to top      =
    =============================================*/
    $(window).on('scroll', function () {
      var scroll = $(window).scrollTop();
      if (scroll < 245) {
        $('#sticky-header').removeClass('sticky-menu');
        $('.scroll-to-target').removeClass('open');
        $('#header-top-fixed').removeClass('header-fixed-position');
      } else {
        $('#sticky-header').addClass('sticky-menu');
        $('.scroll-to-target').addClass('open');
        $('#header-top-fixed').addClass('header-fixed-position');
      }
    });

    /*=============================================
      =    		 Scroll Up  	         =
    =============================================*/
    if ($('.scroll-to-target').length) {
      $('.scroll-to-target').on('click', function () {
        var target = $(this).attr('data-target');
        // animate
        $('html, body').animate(
          {
            scrollTop: $(target).offset().top
          },
          1000
        );
      });
    }
  }, []);

  return (
    <header>
      <div id='header-top-fixed' />
      <div id='sticky-header' className='menu-area main-header'>
        <div className='container custom-container'>
          <div className='row'>
            <div className='col-12'>
              <div className='mobile-nav-toggler'>
                <i className='fas fa-bars' />
              </div>
              <div className='menu-wrap main-menu'>
                <nav className='menu-nav show'>
                  <div className='logo'>
                    <Link to='/#'>
                      <div className='logo-img'></div>
                      {/* <img className='logo-img' src="assets/img/logo/logo.png" alt="" /> */}
                    </Link>
                  </div>
                  <div className='navbar-wrap push-menu d-none d-lg-flex'>
                    <ul className='navigation'>
                      <li>
                        <Link to='/#'>{t('Home')}</Link>
                      </li>
                      <li>
                        <Link to='/getting-started'>{t('GettingStarted')}</Link>
                      </li>
                      <li>
                        <Link to='/roadmap'>{t('Roadmap')}</Link>
                      </li>
                      <li>
                        <Link to='/marketplace'>{t('Marketplace')}</Link>
                      </li>
                      <li>
                        <a
                          href='https://medium.com/@elrondracingmotorsports/about'
                          target='_blank'
                          rel='noreferrer'
                        >
                          {t('Whitepaper')}
                        </a>
                      </li>
                      {/* <li>
                        <Link to='/about-us'>{t('About')}</Link>
                      </li> */}
                      <li>
                        <Link to='/faq'>{t('FAQ')}</Link>
                      </li>
                      {isLoggedIn && (
                        <li>
                          <Link to='/profile'>{t('Profile')}</Link>
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className='header-action'>
                    <ul>
                      <li className='header-search d-flex flex-row'>
                        <div
                          onClick={() => switchLanguage('en')}
                          className='p-1'
                        >
                          <span role='button' className='fi fi-us'></span>
                        </div>
                        <div
                          onClick={() => switchLanguage('ro')}
                          className='p-1'
                        >
                          <span role='button' className='fi fi-ro'></span>
                        </div>
                      </li>
                      {!isLoggedIn && (
                        <li>
                          <span
                            className='btn btn-wallet'
                            onClick={handleClickOpenLoginDialog}
                          >
                            {t('ConnectWallet')}
                          </span>
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className='d-flex'>
                    <ul className='d-xl-none d-lg-none d-flex flex-row align-items-center'>
                      <li className='header-search d-flex flex-row'>
                        <div
                          onClick={() => switchLanguage('en')}
                          className='p-1'
                        >
                          <span role='button' className='fi fi-us'></span>
                        </div>
                        <div
                          onClick={() => switchLanguage('ro')}
                          className='p-1'
                        >
                          <span role='button' className='fi fi-ro'></span>
                        </div>
                      </li>
                      {!isLoggedIn && (
                        <li>
                          <span
                            className='btn btn-wallet'
                            onClick={handleClickOpenLoginDialog}
                          >
                            {t('ConnectWallet')}
                          </span>
                        </li>
                      )}
                    </ul>
                  </div>
                  {isLoggedIn && (
                    <React.Fragment>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          textAlign: 'center'
                        }}
                      >
                        <Tooltip title='Account settings'>
                          <IconButton
                            onClick={handleClick}
                            size='small'
                            sx={{ ml: 2 }}
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup='true'
                            aria-expanded={open ? 'true' : undefined}
                          >
                            <Avatar
                              sx={{ width: 32, height: 32 }}
                              src='/assets/img/logo/logo-mobile.png'
                            >
                              M
                            </Avatar>
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <ThemeProvider theme={darkTheme}>
                        <Menu
                          anchorEl={anchorEl}
                          id='account-menu'
                          open={open}
                          onClose={handleClose}
                          onClick={handleClose}
                          PaperProps={{
                            elevation: 0,
                            sx: {
                              overflow: 'visible',
                              filter:
                                'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                              mt: 1.5,
                              '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1
                              },
                              '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0
                              }
                            }
                          }}
                          transformOrigin={{
                            horizontal: 'right',
                            vertical: 'top'
                          }}
                          anchorOrigin={{
                            horizontal: 'right',
                            vertical: 'bottom'
                          }}
                        >
                          <MenuItem>
                            <Avatar /> <Link to='/profile'>{cut(address)}</Link>
                          </MenuItem>
                          <Divider />
                          <MenuItem>
                            <ListItemIcon>
                              <Settings fontSize='small' />
                            </ListItemIcon>
                            Settings
                          </MenuItem>
                          <MenuItem onClick={handleClickOpenLoginDialog}>
                            <ListItemIcon>
                              <Logout fontSize='small' />
                            </ListItemIcon>
                            <li>
                              <span className='btn btn-wallet'>
                                {t('DisconnectWallet')}
                              </span>
                            </li>
                          </MenuItem>
                        </Menu>
                      </ThemeProvider>
                    </React.Fragment>
                  )}
                </nav>
              </div>
              {/* Mobile Menu  */}
              <div className='mobile-menu'>
                <nav className='menu-box'>
                  <div className='close-btn'>
                    <i className='fas fa-times' />
                  </div>
                  <div className='nav-logo'>
                    <Link to='/#'>
                      <img src='assets/img/logo/logo.png' alt='' title='true' />
                    </Link>
                  </div>
                  <div className='menu-outer'>
                    <ul className='navigation'>
                      <li>
                        <Link to='/#'>{t('Home')}</Link>
                      </li>
                      <li>
                        <Link to='/getting-started'>{t('GettingStarted')}</Link>
                      </li>
                      <li>
                        <Link to='/roadmap'>{t('Roadmap')}</Link>
                      </li>
                      <li>
                        <Link to='/marketplace'>{t('Marketplace')}</Link>
                      </li>
                      <li>
                        <a
                          href='https://medium.com/@elrondracingmotorsports/about'
                          target='_blank'
                          rel='noreferrer'
                        >
                          {t('Whitepaper')}
                        </a>
                      </li>
                      {/* <li>
                        <Link to='/about-us'>{t('About')}</Link>
                      </li> */}
                      <li>
                        <Link to='/faq'>{t('FAQ')}</Link>
                      </li>
                      {isLoggedIn && (
                        <li>
                          <Link to='/profile'>{t('Profile')}</Link>
                        </li>
                      )}
                    </ul>

                    {/*Here Menu Will Come Automatically Via Javascript / Same Menu as in Header*/}
                  </div>
                  <div className='p-4'>
                    <span
                      className='btn btn-wallet'
                      onClick={handleClickOpenLoginDialog}
                    >
                      {isLoggedIn ? t('DisconnectWallet') : t('ConnectWallet')}
                    </span>
                  </div>
                  <div className='p-4 pt-0 pb-0'>
                    <li className='header-search d-flex flex-row'>
                      <div onClick={() => switchLanguage('en')} className='p-1'>
                        <span role='button' className='fi fi-us'></span>
                      </div>
                      <div onClick={() => switchLanguage('ro')} className='p-1'>
                        <span role='button' className='fi fi-ro'></span>
                      </div>
                    </li>
                  </div>
                  <div className='social-links'>
                    <ul className='clearfix'>
                      <li>
                        <a href='/discord'
                          target='_blank'
                          rel='noreferrer'>
                          <i className='fab fa-discord' />
                        </a>
                      </li>
                      <li>
                        <a href='https://fb.me/erms.ro'
                          target='_blank'
                          rel='noreferrer'>
                          <i className='fab fa-facebook-f' />
                        </a>
                      </li>
                      <li>
                        <a href='https://twitter.com/erms_ro'
                          target='_blank'
                          rel='noreferrer'>
                          <i className='fab fa-twitter' />
                        </a>
                      </li>
                      <li>
                        <a href='/#'>
                          <i className='fab fa-youtube' />
                        </a>
                      </li>
                      <li>
                        <a href='https://instagram.com/erms.ro'
                          target='_blank'
                          rel='noreferrer'>
                          <i className='fab fa-instagram' />
                        </a>
                      </li>
                      <li>
                        <a href='https://tiktok.com/@erms.ro'
                          target='_blank'
                          rel='noreferrer'>
                          <i className='fab fa-tiktok' />
                        </a>
                      </li>
                      <li>
                        <a href='https://t.me/ermsannouncements'
                          target='_blank'
                          rel='noreferrer'>
                          <i className='fab fa-telegram' />
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className='menu-backdrop' />
              {/* End Mobile Menu */}
            </div>
          </div>
        </div>
        <div className='header-icon'>
          <Link to='/#' className='menu-tigger'>
            <img src='assets/img/icon/hader_icon.png' alt='' />
          </Link>
        </div>
      </div>
      {/* header-search */}
      <div
        className='search-popup-wrap'
        tabIndex={-1}
        role='dialog'
        aria-hidden='true'
      >
        <div className='search-close'>
          <span>
            <i className='fas fa-times' />
          </span>
        </div>
        <div className='search-wrap text-center'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <h2 className='title'>... Search Here ...</h2>
                <div className='search-form'>
                  <form action='#'>
                    <input
                      type='text'
                      name='search'
                      placeholder='Type keywords here'
                    />
                    <button className='search-btn'>
                      <i className='fas fa-search' />
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* header-search-end */}
      {/* offCanvas-menu */}
      {/* <div className='extra-info'>
        <div className='close-icon menu-close'>
          <button>
            <i className='far fa-window-close' />
          </button>
        </div>
        <div className='logo-side mb-30'>
          <Link to='/#'>
            <img src='assets/img/logo/logo.png' alt='Logo' />
          </Link>
        </div>
        <div className='side-info mb-30'>
          <div className='contact-list mb-30'>
            <h4>Office Address</h4>
            <p>
              123/A, Miranda City Likaoli <br /> Prikano, Dope
            </p>
          </div>
          <div className='contact-list mb-30'>
            <h4>Phone Number</h4>
            <p>+0989 7876 9865 9</p>
            <p>+(090) 8765 86543 85</p>
          </div>
          <div className='contact-list mb-30'>
            <h4>Email Address</h4>
            <p>info@example.com</p>
            <p>example.mail@hum.com</p>
          </div>
        </div>
        <ul className='side-instagram'>
          <li>
            <a href='/our-squad'>
              <img src='assets/img/images/sd_insta01.jpg' alt='' />
            </a>
          </li>
          <li>
            <a href='/our-squad'>
              <img src='assets/img/images/sd_insta02.jpg' alt='' />
            </a>
          </li>
          <li>
            <a href='/our-squad'>
              <img src='assets/img/images/sd_insta03.jpg' alt='' />
            </a>
          </li>
          <li>
            <a href='/our-squad'>
              <img src='assets/img/images/sd_insta04.jpg' alt='' />
            </a>
          </li>
          <li>
            <a href='/our-squad'>
              <img src='assets/img/images/sd_insta05.jpg' alt='' />
            </a>
          </li>
          <li>
            <a href='/our-squad'>
              <img src='assets/img/images/sd_insta06.jpg' alt='' />
            </a>
          </li>
        </ul>
        <div className='social-icon-right mt-30'>
          <a href='/#'>
            <i className='fab fa-facebook-f' />
          </a>
          <a href='/#'>
            <i className='fab fa-twitter' />
          </a>
          <a href='/#'>
            <i className='fab fa-google-plus-g' />
          </a>
          <a href='/#'>
            <i className='fab fa-instagram' />
          </a>
        </div>
      </div> */}
      <div className='offcanvas-overly' />
      {/* offCanvas-menu-end  */}
      {/* Login Dialog content */}
      <BootstrapDialog
        onClose={handleCloseLoginDialog}
        aria-labelledby='customized-dialog-title'
        open={openLoginDialog}
        style={{ zIndex: 100 }}
      >
        <BootstrapDialogTitle
          id='customized-dialog-title'
          onClose={handleCloseLoginDialog}
        >
          <Typography variant='h6' sx={{ color: '#fff', fontWeight: 'bold' }}>
            Wallet Login
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent
          dividers
          sx={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}
        >
          <div className='d-flex justify-content-center'>
            <ExtensionLoginButton
              loginButtonText={'Extension'}
              callbackRoute={routeNames.logedin}
            />
            <WebWalletLoginButton
              loginButtonText={'Web wallet'}
              callbackRoute={routeNames.logedin}
            />
          </div>
          <div className='d-flex justify-content-center'>
            <LedgerLoginButton
              loginButtonText={'Ledger'}
              className={'test-class_name'}
              callbackRoute={routeNames.logedin}
            />
            <WalletConnectLoginButton
              loginButtonText={'Maiar'}
              callbackRoute={routeNames.logedin}
            />
          </div>
        </DialogContent>
      </BootstrapDialog>
      {/* Capture email dialog */}
      <BootstrapDialog
        aria-labelledby='customized-dialog-title'
        open={openEmailDialog}
        style={{ zIndex: 100 }}
      >
        <BootstrapDialogTitle id='customized-dialog-title'>
          <Typography variant='h6' sx={{ color: '#fff', fontWeight: 'bold' }}>
            Enter your email to continue
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent
          dividers
          sx={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}
        >
          <div className='d-flex justify-content-center'>
            <CssTextField
              label='Your Email'
              id='custom-css-outlined-input'
              sx={{ input: { color: 'white' }, paddingRight: 2 }}
              autoFocus
              type='email'
              onChange={(e) => setEmailCapture(e.target.value)}
            />
            <ColorButton onClick={addEmail}>SEND</ColorButton>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </header>
  );
}

export default HeaderOne;
