import React from 'react';
import { useTranslation } from 'react-i18next';

function SquadSingle() {
  const { t, i18n } = useTranslation('common');
  return (
    <section className='our-squad-area black-bg'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-xl-3 col-lg-4 col-md-6 col-sm-9'>
            <div className='squad-item'>
              <div className='squad-thumb'>
                <a href='/#'>
                  <img src='assets/img/squad/alin.png' alt='' />
                </a>
              </div>
              <div className='squad-content'>
                <div className='squad-info'>
                  <h4 className='title'>
                    <a href='/#'>Alin Costa</a>
                  </h4>
                  <span>Owner</span>
                </div>
                <div className='squad-social'>
                  <div className='social-list'>
                    <a
                      href='https://tiktok.com/@alincosta.eth'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <i className='fab fa-tiktok' />
                    </a>
                    <a
                      href='https://twitter.com/alincosta333'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <i className='fab fa-twitter-square' />
                    </a>
                    <a
                      href='https://youtube.com/c/AlinCostaERMS'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <i className='fab fa-youtube' />
                    </a>
                    <a
                      href='https://instagram.com/instacosta777'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <i className='fab fa-instagram' />
                    </a>
                    <a
                      href='https://github.com/costaromania'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <i className='fab fa-github' />
                    </a>
                  </div>
                  <span className='follow-us'>{t('follow')}</span>
                </div>
              </div>
            </div>
          </div>
          <div className='col-xl-3 col-lg-4 col-md-6 col-sm-9'>
            <div className='squad-item'>
              <div className='squad-thumb'>
                <a href='/#'>
                  <img src='assets/img/squad/dragos.png' alt='' />
                </a>
              </div>
              <div className='squad-content'>
                <div className='squad-info'>
                  <h4 className='title'>
                    <a href='/#'>Dragos Catalin</a>
                  </h4>
                  <span>Developer</span>
                </div>
                <div className='squad-social'>
                  <div className='social-list'>
                    <a
                      href='https://tiktok.com/@dragosv.me'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <i className='fab fa-tiktok' />
                    </a>
                    <a
                      href='https://instagram.com/dragosv.fun'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <i className='fab fa-instagram' />
                    </a>
                    <a
                      href='https://github.com/dragoscv'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <i className='fab fa-github' />
                    </a>
                  </div>
                  <span className='follow-us'>{t('follow')}</span>
                </div>
              </div>
            </div>
          </div>
          <div className='col-xl-3 col-lg-4 col-md-6 col-sm-9'>
            <div className='squad-item'>
              <div className='squad-thumb'>
                <a href='/#'>
                  <img src='assets/img/squad/andrei.png' alt='' />
                </a>
              </div>
              <div className='squad-content'>
                <div className='squad-info'>
                  <h4 className='title'>
                    <a href='/#'>Andrei Florin</a>
                  </h4>
                  <span>Developer</span>
                </div>
                <div className='squad-social'>
                  <div className='social-list'>
                    <a
                      href='https://tiktok.com/@adaieandrei'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <i className='fab fa-tiktok' />
                    </a>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://instagram.com/adaieandrei'
                    >
                      <i className='fab fa-instagram' />
                    </a>
                    <a
                      href='https://github.com/adaieandrei123'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <i className='fab fa-github' />
                    </a>
                  </div>
                  <span className='follow-us'>{t('follow')}</span>
                </div>
              </div>
            </div>
          </div>
          <div className='col-xl-3 col-lg-4 col-md-6 col-sm-9'>
            <div className='squad-item'>
              <div className='squad-thumb'>
                <a href='/#'>
                  <img src='assets/img/squad/profile.png' alt='' />
                </a>
              </div>
              <div className='squad-content'>
                <div className='squad-info'>
                  <h4 className='title'>
                    <a href='/#'>You</a>
                  </h4>
                  <span>{t('BecomeATeamMember')}</span>
                </div>
                <div className='squad-social'>
                  <div className='social-list'>
                    <a href='/#'>
                      <i className='fab fa-twitter-square' />
                    </a>
                    <a href='/#'>
                      <i className='fab fa-vimeo' />
                    </a>
                    <a href='/#'>
                      <i className='fab fa-pinterest' />
                    </a>
                    <a href='/#'>
                      <i className='fab fa-twitch' />
                    </a>
                  </div>
                  <span className='follow-us'>{t('ContactUs')}</span>
                </div>
              </div>
            </div>
          </div>
          {/* <div className='row'>
            <div className='col-12'>
              <div className='load-more-squad text-center'>
                <a href='/our-squad' className='btn'>
                  load more squad
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default SquadSingle;
