import React from "react"
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { app } from "../../../firebase.config";
import {
  DappUI,
  useGetLoginInfo,
  logout,
  useGetAccountInfo,
  getAccount
} from '@elrondnetwork/dapp-core';

const ProfileSettings = () => {
  const storage = getStorage(app);
  const { address, account } = useGetAccountInfo();
  const storageRef = ref(storage, address + '/profile.png');
  const [file, setFile] = React.useState(null);
  const [url, setURL] = React.useState("");
  const [storageImage, setStorageImage] = React.useState("");

  React.useEffect(() => {
    getDownloadURL(storageRef).then(url => {
      setStorageImage(url + '?alt=media');
    });
  }, []);

  function handleChange(e) {
    if (e.target.files[0])
      setFile(e.target.files[0]);
  }

  const metadata = {
    contentType: 'image/png',
  };

  async function handleUpload(e) {
    e.preventDefault();
    uploadBytes(storageRef, file, metadata).then(() => {
      setFile(null);
      getDownloadURL(storageRef).then(url => {
        setStorageImage(url + '?alt=media');
      });
    });
  }

  return (
    <>
      <div>
        <form onSubmit={handleUpload}>
          {storageImage && <img className='profile-setting-img' src={storageImage} alt="profile" />}
          <input type="file" onChange={handleChange} />
          <button disabled={!file}>Save</button>
        </form>
      </div>
    </>
  )
}

export default ProfileSettings;