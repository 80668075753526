import React from 'react';
import { AuthenticatedRoutesWrapper } from '@elrondnetwork/dapp-core';
import { useLocation } from 'react-router-dom';
import routes, { routeNames } from 'routes';
import { useNavigate } from 'react-router-dom';
import { createHash } from "crypto";

const Layout = ({ children }: { children: React.ReactNode }) => {
  const isPreview: any = process.env.REACT_APP_PREVIEW!;
  const environment: any = process.env.NODE_ENV!;
  const pass: any = process.env.REACT_APP_PP!;
  const { search } = useLocation();
  const navigate = useNavigate();
  const isAllowed = sessionStorage.getItem('allow');  

  const hash = (string:string) => {
    return createHash('sha256').update(string).digest('hex');
}

  React.useEffect(() => {
    console.log(environment);
    if (environment != 'development') {
      if (isPreview) {
        if (isAllowed != hash(pass)) {
          navigate('/dev');
        }
      }
    }
  }, []);

  return (
    <div className='bg-dark d-flex flex-column flex-fill wrapper'>
      {/* <Navbar /> */}
      <main className='d-flex flex-column flex-grow-1'>
        <AuthenticatedRoutesWrapper
          routes={routes}
          unlockRoute={`${routeNames.unlock}${search}`}
        >
          {children}
        </AuthenticatedRoutesWrapper>
      </main>
      {/* <Footer /> */}
    </div>
  );
};

export default Layout;
