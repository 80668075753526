import React from 'react';
import { DappUI, DappProvider } from '@elrondnetwork/dapp-core';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { getAuth, signInAnonymously, onAuthStateChanged } from 'firebase/auth';
import CookieConsent from 'react-cookie-consent';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import Layout from 'components/Layout';
import PageNotFound from 'pages/PageNotFound';
import { routeNames } from 'routes';
import routes from 'routes';
import { app } from './firebase.config';
import '@elrondnetwork/dapp-core/build/index.css';
import { Typography, TypographyVariantsOptions } from '@mui/material';
import { width } from '@mui/system';
const PreLoader = require('react-preloaders2').Sugar;

const environment: any = process.env.REACT_APP_ENVIRONMENT!;

const {
  TransactionsToastList,
  SignTransactionsModals,
  NotificationModal,
  DappCorePages: { UnlockPage }
} = DappUI;

const App = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const allow = sessionStorage.getItem('allow') === 'true';


  React.useEffect(() => {
    // console.log(environment);
    document.addEventListener("DOMContentLoaded", function () {
      setIsLoading(false);
    });
    const auth = getAuth(app);
    const analytics = getAnalytics(app);
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        signInAnonymously(auth)
          .then(() => {
            logEvent(analytics, 'sign_in', {
              anonymous: true
            });
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode + errorMessage);
          });
      }
    });
    const script = document.createElement('script');

    script.src = "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
    script.async = true;

    document.body.appendChild(script);
  }, []);
  return (
    <Router>
      <DappProvider
        environment={environment}
        customNetworkConfig={{ name: 'customConfig', apiTimeout: 6000 }}
        completedTransactionsDelay={200}
      >
        <Layout>
          <PreLoader customLoading={isLoading} />
          <TransactionsToastList />
          <NotificationModal />
          <SignTransactionsModals className='custom-class-for-modals' />
          <Routes>
            <Route
              path={routeNames.unlock}
              element={<UnlockPage loginRoute={routeNames.home} />}
            />
            {routes.map((route: any, index: number) => (
              <Route
                path={route.path}
                key={'route-key-' + index}
                element={<route.component />}
              />
            ))}
            <Route path='*' element={<PageNotFound />} />
          </Routes>
          <CookieConsent
            location='bottom'
            buttonText='Sure man!!'
            cookieName='myAwesomeCookieName2'
            style={{ background: '#2B373B', maxWidth:'100vw' }}
            buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
            expires={1}
          >
              <Typography style={{display: 'inline-block over'}} display="inline">
                This website uses cookies to enhance the user experience.
                Beware of scammers!
                Always check your url to be&nbsp;
              </Typography>
              <Typography style={{display: 'inline-block'}} sx={{ color: '#ff0000' }} display="inline">
                https://erms.ro/
              </Typography>
              {' '}
            {/* <span>This bit of text is smaller :O</span> */}
          </CookieConsent>
        </Layout>
      </DappProvider>
    </Router>
  );
};

export default App;
