import React from 'react';
import '../../styles/animate.min.css';
import { DappUI, useGetLoginInfo, logout } from '@elrondnetwork/dapp-core';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { routeNames } from 'routes';

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText('#ff8503'),
  fontWeight: 'bold',
  backgroundColor: '#ff8503',
  '&:hover': {
    backgroundColor: '#e19b51'
  }
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

function Banner() {
  const { isLoggedIn } = useGetLoginInfo();
  const [openLoginDialog, setOpenLoginDialog] = React.useState(false);
  const { t, i18n } = useTranslation('common');

  React.useEffect(() => {
    const lang = localStorage.getItem('i18nextLng');
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, []);

  const {
    ExtensionLoginButton,
    WebWalletLoginButton,
    LedgerLoginButton,
    WalletConnectLoginButton
  } = DappUI;

  // React.useEffect(() => {
  //   if (isLoggedIn) {
  //     window.location.href = routeNames.home;
  //   }
  // }, [isLoggedIn]);

  const handleClickOpenLoginDialog = () => {
    if (isLoggedIn) {
      logout();
    } else {
      setOpenLoginDialog(true);
    }
  };
  const handleCloseLoginDialog = () => {
    setOpenLoginDialog(false);
  };
  return (
    <>
      <div className='banner-area-three banner-three-bg'>
        <div className='container banner1'>
          <div className='row align-items-center justify-content-center'>
            <div className='col-lg-6 col-md-9 order-0 order-lg-2'>
              <div className='banner-img-wrap'>
                <div className='banner-img-item'>
                  <img
                    src='assets/img/slider/slider_shape01.png'
                    alt=''
                    className='shape'
                  />
                  <img
                    src='assets/img/motos/moto1t.png'
                    alt=''
                    className='main-img main-img-1'
                  />
                </div>
                <div className='banner-img-item'>
                  <img
                    src='assets/img/slider/slider_shape02.png'
                    alt=''
                    className='shape'
                  />
                  <img
                    src='assets/img/motos/moto4t.png'
                    alt=''
                    className='main-img main-img-2'
                  />
                </div>
                <div className='banner-img-item'>
                  <img
                    src='assets/img/slider/slider_shape03.png'
                    alt=''
                    className='shape'
                  />
                  <img
                    src='assets/img/motos/moto3t.png'
                    alt=''
                    className='main-img'
                  />
                </div>
                <div className='banner-img-item'>
                  <img
                    src='assets/img/slider/slider_shape04.png'
                    alt=''
                    className='shape'
                  />
                  <img
                    src='assets/img/motos/moto2t.png'
                    alt=''
                    className='main-img'
                  />
                </div>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='banner-content'>
                <h4 className='small-title animate__animated animate__fadeInUp animate__delay-.2s '>
                  {t('banner1.title1')}
                </h4>
                <h2 className='title animate__animated animate__fadeInUp animate__delay-.4s'>
                  <i className='fas fa-globe' />
                  {t('banner1.title2')} <br />
                  <span>{t('banner1.title3')}</span>
                </h2>
                <p className='animate__animated animate__fadeInUp animate__delay-.6s'>
                  {t('banner1.text')}
                </p>
                <div className='banner-btn animate__animated animate__fadeInUp animate__delay-.8s'>
                  {/* {isLoggedIn ? (<a href='/profile' className='btn'>) : (<a href='/login' className='btn'>)}
                    {isLoggedIn ? 'View Profile' : 'Connect Wallet'}
                    <i className='fas fa-angle-right' />
                  </a> */}
                  {isLoggedIn ? (
                    <>
                      <a href='/profile' className='btn'>
                        {t('banner1.button1')}
                        <i className='fas fa-angle-right' />
                      </a>
                    </>
                  ) : (
                    <span className='btn' onClick={handleClickOpenLoginDialog}>
                      {t('banner1.button2')}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BootstrapDialog
        onClose={handleCloseLoginDialog}
        aria-labelledby='customized-dialog-title'
        open={openLoginDialog}
        style={{ zIndex: 100 }}
      >
        <BootstrapDialogTitle
          id='customized-dialog-title'
          onClose={handleCloseLoginDialog}
        >
          Wallet Login
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <ExtensionLoginButton
            loginButtonText={'Extension'}
            callbackRoute={routeNames.home}
          />
          <WebWalletLoginButton
            loginButtonText={'Web wallet'}
            callbackRoute={routeNames.home}
          />
          <LedgerLoginButton
            loginButtonText={'Ledger'}
            className={'test-class_name'}
            callbackRoute={routeNames.home}
          />
          <WalletConnectLoginButton
            loginButtonText={'Maiar'}
            callbackRoute={routeNames.home}
          />
        </DialogContent>
      </BootstrapDialog>
    </>
  );
}

export default Banner;
