import React, { useEffect } from 'react';
import '../../styles/animate.min.css';
import { DappUI, useGetLoginInfo, logout } from '@elrondnetwork/dapp-core';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { routeNames } from 'routes';

function GettingStartedComponent() {
  const { t, i18n } = useTranslation('common');
  const [openLoginDialog, setOpenLoginDialog] = React.useState(false);

  const {
    ExtensionLoginButton,
    WebWalletLoginButton,
    LedgerLoginButton,
    WalletConnectLoginButton
  } = DappUI;
  const { isLoggedIn } = useGetLoginInfo();

  React.useEffect(() => {
    const lang = localStorage.getItem('i18nextLng');
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, []);

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2)
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1)
    }
  }));

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label='close'
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  const handleClickOpenLoginDialog = () => {
    if (isLoggedIn) {
      logout();
    } else {
      setOpenLoginDialog(true);
    }
  };
  const handleCloseLoginDialog = () => {
    setOpenLoginDialog(false);
  };
  return (
    <div className='banner-area-two banner-bg'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-xxl-8 col-lg-9'>
            <div className='banner-content text-center'>
              <div className='d-flex flex-column flex-lg-row justify-content-center align-items-center m-t-4'>
                <div className='flex-sm-row col-lg-4 m-4'>
                  <Card
                    sx={{
                      minWidth: 275,
                      maxWidth: 275,
                      height: 400,
                      backgroundColor: '#212121',
                      opacity: 0.8
                    }}
                  >
                    <CardContent>
                      <div>
                        <img
                          className='img-step1'
                          src='assets/img/step1.png'
                          alt=''
                        />
                      </div>
                      <Typography
                        sx={{ fontSize: 12, color: '#fff', fontWeight: 'bold' }}
                        color='text.secondary'
                        gutterBottom
                      >
                        {t('GettingStartedStep1')}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <div className='d-flex justify-content-center'>
                        <span
                          className='btn btn-steps'
                          onClick={handleClickOpenLoginDialog}
                        >
                          {isLoggedIn
                            ? t('DisconnectWallet')
                            : t('GettingStartedStep1Button')}
                        </span>
                      </div>
                    </CardActions>
                  </Card>
                </div>
                <div className='col-lg-4 m-4'>
                  <Card
                    sx={{
                      minWidth: 275,
                      maxWidth: 275,
                      height: 400,
                      backgroundColor: '#212121',
                      opacity: 0.8
                    }}
                  >
                    <CardContent>
                      <div>
                        <img
                          className='img-step1'
                          src='assets/img/erms.png'
                          alt=''
                        />
                      </div>
                      <Typography
                        sx={{ fontSize: 12, color: '#fff', fontWeight: 'bold' }}
                        color='text.secondary'
                        gutterBottom
                      >
                        {t('GettingStartedStep2')}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <span className='btn btn-steps'>
                        {t('GettingStartedStep2Button')}
                      </span>
                    </CardActions>
                  </Card>
                </div>
                <div className='col-lg-4 m-4'>
                  <Card
                    sx={{
                      minWidth: 275,
                      maxWidth: 275,
                      height: 400,
                      backgroundColor: '#212121',
                      opacity: 0.8
                    }}
                  >
                    <CardContent>
                      <div>
                        <img
                          className='img-step1'
                          src='assets/img/step3.png'
                          alt=''
                        />
                      </div>
                      <Typography
                        sx={{ fontSize: 12, color: '#fff', fontWeight: 'bold' }}
                        color='text.secondary'
                        gutterBottom
                      >
                        {t('GettingStartedStep3')}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <a
                        href='https://xoxno.com/buy/ERMS/Assets'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <span className='btn btn-steps'>
                          {t('GettingStartedStep3Button')}
                        </span>
                      </a>
                    </CardActions>
                  </Card>
                </div>
              </div>
              {/* <h4 className='small-title animate__animated animate__fadeInUp animate__delay-.2s'>
                the Season 8
              </h4>
              <h2 className='title animate__animated animate__fadeInUp animate__delay-.4s'>
                steam
              </h2>
              <div className='banner-btn animate__animated animate__fadeInUp animate__delay-.6s'>
                <a href='/match-single' className='btn'>
                  watch live
                  <i className='fas fa-play-circle' />
                </a>
                <a href='/contact' className='text-btn'>
                  dream making
                </a> 
            </div>*/}
            </div>
          </div>
        </div>
      </div>
      <BootstrapDialog
        onClose={handleCloseLoginDialog}
        aria-labelledby='customized-dialog-title'
        open={openLoginDialog}
        style={{ zIndex: 100 }}
      >
        <BootstrapDialogTitle
          id='customized-dialog-title'
          onClose={handleCloseLoginDialog}
        >
          Wallet Login
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <ExtensionLoginButton
            loginButtonText={'Extension'}
            callbackRoute={routeNames.gettingStarted}
          />
          <WebWalletLoginButton
            loginButtonText={'Web wallet'}
            callbackRoute={routeNames.gettingStarted}
          />
          <LedgerLoginButton
            loginButtonText={'Ledger'}
            className={'test-class_name'}
            callbackRoute={routeNames.gettingStarted}
          />
          <WalletConnectLoginButton
            loginButtonText={'Maiar'}
            callbackRoute={routeNames.gettingStarted}
          />
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

export default GettingStartedComponent;
