import React, { useLayoutEffect, useState } from 'react';
import {
    DappUI,
    useGetLoginInfo,
    logout,
    useGetAccountInfo,
    getAccount
} from '@elrondnetwork/dapp-core';
import { Unity, useUnityContext } from "react-unity-webgl";
import { useNavigate } from 'react-router-dom';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { app } from '../firebase.config';
import { getRemoteConfig, getValue, fetchAndActivate } from "firebase/remote-config";

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Box sx={{ width: '100%', color: '#ff8503' }}>
                <LinearProgress style={{ height: 10 }} color="inherit" variant="determinate" {...props} />
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

const TryGame = () => {
    const environment = process.env.REACT_APP_ENVIRONMENT;
    var uri = '';
    var collectionsEnv = '';
    if (environment === 'testnet') {
        uri = 'https://testnet-api.elrond.com/';
        collectionsEnv = 'collections_dev';
    } else if (environment === 'mainnet') {
        uri = 'https://api.elrond.com/';
        collectionsEnv = 'collections_prod';
    } else if (environment === 'devnet') {
        uri = 'https://devnet-api.elrond.com/';
        collectionsEnv = 'collections_dev';
    }
    const { address, account } = useGetAccountInfo();
    const [availableCollections, setAvailableCollections] = useState([]);
    const remoteConfig = getRemoteConfig(app);
    remoteConfig.settings.minimumFetchIntervalMillis = 0;
    fetchAndActivate(remoteConfig);
    const remoteConfigValue = getValue(remoteConfig, collectionsEnv);
    const remoteValue = remoteConfigValue._value;
    // remoteConfig.defaultConfig = {
    //     "collections_dev": "Welcome"
    // };
    const [width, height] = useWindowSize();
    const { unityProvider, isLoaded, loadingProgression } = useUnityContext(
        isBrowser && {

            loaderUrl: 'https://storage.erms.ro/unitybuild/test/test.loader.js',
            dataUrl: 'https://storage.erms.ro/unitybuild/test/test.data',
            frameworkUrl: 'https://storage.erms.ro/unitybuild/test/test.framework.js',
            codeUrl: 'https://storage.erms.ro/unitybuild/test/test.wasm'

            // loaderUrl: 'https://storage.erms.ro/unitybuild/2021.3/keyboard/keyboard.loader.js',
            // dataUrl: 'https://storage.erms.ro/unitybuild/2021.3/keyboard/keyboard.data',
            // frameworkUrl: 'https://storage.erms.ro/unitybuild/2021.3/keyboard/keyboard.framework.js',
            // codeUrl: 'https://storage.erms.ro/unitybuild/2021.3/keyboard/keyboard.wasm'
        } || isMobile && {
            loaderUrl: 'https://storage.erms.ro/unitybuild/2021.3/touch/touch.loader.js',
            dataUrl: 'https://storage.erms.ro/unitybuild/2021.3/touch/touch.data',
            frameworkUrl: 'https://storage.erms.ro/unitybuild/2021.3/touch/touch.framework.js',
            codeUrl: 'https://storage.erms.ro/unitybuild/2021.3/touch/touch.wasm'
        });
    // } || isMobile && {
    //     loaderUrl: 'https://storage.erms.ro/unitybuild/2021.3/mobile/erms.loader.js',
    //     dataUrl: 'https://storage.erms.ro/unitybuild/2021.3/mobile/erms.data',
    //     frameworkUrl: 'https://storage.erms.ro/unitybuild/2021.3/mobile/erms.framework.js',
    //     codeUrl: 'https://storage.erms.ro/unitybuild/2021.3/mobile/erms.wasm'
    // });

    // const { unityProvider, isLoaded, loadingProgression } = useUnityContext({
    //     loaderUrl: 'https://storage.erms.ro/unitybuild/test/test.loader.js',
    //     dataUrl: 'https://storage.erms.ro/unitybuild/test/test.data',
    //     frameworkUrl: 'https://storage.erms.ro/unitybuild/test/test.framework.js',
    //     codeUrl: 'https://storage.erms.ro/unitybuild/test/test.wasm'
    // });
    const { isLoggedIn } = useGetLoginInfo();
    const navigate = useNavigate();


    React.useEffect(async () => {
        console.log(address);
        if (!isLoggedIn) {
            navigate('/');
        }
        const hasNFT = await fetch(uri + 'accounts/' + address + '/nfts').then((res) => {
            res.json().then((data) => {
                console.log(data);
                if (remoteValue) {
                    console.log(remoteValue);
                    const collections = JSON.parse(remoteValue);
                    // collections.map(collection => {
                    //     console.log(collection);
                    // });
                    if (data.some(item => item.collection === collections[0].name)) {
                        console.log('has nft in collection '+ collections[0].name);
                    } else {
                        navigate('/');
                    }
                }
            });
        });
        // if (!hasNFT) {
        //     navigate('/');
        // }
    }, [remoteValue]);
    const loadingPercentage = Math.round(loadingProgression * 100);

    return (
        <main>
            {isLoaded === false && (
                <div className='game-background'>
                    <div className='loading-overlay d-flex justify-content-center'>
                        <div className='banner-content text-center'>
                            <div className='title p-2 pt-3 layer animate__animated animate__fadeInUp animate__delay-.4s title-game'>ERMS</div>
                            <img src='/assets/img/unity/keyboard.png' className='loading-keyboard' />
                            <Box sx={{ width: '100%' }}>
                                <LinearProgressWithLabel value={loadingPercentage} />
                            </Box>
                            <p className='loading-progress'>Loading... ({loadingPercentage}%)</p>
                        </div>
                    </div>
                </div>
            )}
            <div className={(isLoaded === false && 'd-none ') + 'game d-flex justify-content-center align-items-center'}>
                <BrowserView>
                    <Unity unityProvider={unityProvider} style={{ width: width - 10, height: height - 10 }} />
                </BrowserView>
                <MobileView>
                    <Unity unityProvider={unityProvider} style={{ width: width - 10, height: height - 10 }} />
                </MobileView>
            </div>
        </main>
    );
}
export default TryGame;